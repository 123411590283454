import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import InvalidPageStyles from "./404.module.scss";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <div className={InvalidPageStyles.container}>
      <SEO title="404: Not found" />
      <p>uh-oh! Something's missing</p>
      <img src="../../404.png"></img>
      <h4>PAGE NOT FOUND</h4>
      <p>
        The page you are looking for might have been removed,<br/> had its name
        changed or is temporarily unavailable.
      </p>
      <div className={InvalidPageStyles.linkWrapper}>
        <Link to="/" className={InvalidPageStyles.homeLink}>
          GO TO HOMEPAGE
        </Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
